import React, { useState } from 'react';
import GalleryItem from './GalleryItem'
import { useTranslation } from 'react-i18next';
import Carousel from 'better-react-carousel'
import Portfolio1 from './Portfolio1'



function Films(){
    const { t, i18n } = useTranslation();
    
   
    const [playLig, setPlayLig] = useState(false);
    const [playDuel, setPlayDuel] = useState(false);
    const [playTraffic, setPlayTraffic] = useState(false);

    
    function closeVideo(id){
        if (id == "lig"){
            setPlayLig(false)
        }
        else if (id == "duel"){
            setPlayDuel(false)
        }
        else if (id == "traffic"){
            setPlayTraffic(false)
        }
    }

   

    function playvideo(id){
       if (id == "lig"){
            setPlayLig(true)
        }
        else if (id == "duel"){
            setPlayDuel(true)
        }
        else if (id == "traffic"){
            setPlayTraffic(true)
        }
    }

    return(
        <section className="page-section portfolio" id="films">
            <div className="container">    
                <h2 className="page-section-heading text-center text-uppercase titlewhite   mb-0 font-face-francois">Sound for Films</h2> 
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-film"></i></div>
                    <div className="divider-custom-line"></div>
                </div>


                <Portfolio1 closeVideo={() => closeVideo("lig")} id= "portfolioModal3" title = {t('tituloport3')} desc1 = {t('descport31')} source = "https://www.youtube.com/embed/zmZ_tQw0aac" play={playLig}/>

                <Portfolio1 closeVideo={() => closeVideo("duel")} id= "portfolioModal9" title = {t('tituloport9')} desc1 = {t('descport9')} source = "https://www.dropbox.com/scl/fi/yhteawgyzm8rqr4fn8bwl/The-Duel.mp4?rlkey=lyg9b8tj7cmayrwham1vkxtiq&dl=0"   play={playDuel}/>

                <Portfolio1 closeVideo={() => closeVideo("traffic")} id= "portfolioModal10" title = {t('tituloport10')} desc1 = {t('descport10')} source = "https://www.dropbox.com/scl/fi/bmhsfbx22w2nco1vmevl4/Traffic-v3.mp4?rlkey=46qjtt4rzemfn3bm5mq0szrjq&dl=0"   play={playTraffic}/>

                    <div className="row mb-1 justify-content-center text-center">

                        <div className="col-12 col-md-4  mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal9">
                                    <div onClick={() => playvideo("duel")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img className="img-fluid" src="./images/duel.png" alt="..." />
                            </div>
                        </div>

                        <div className="col-12 col-md-4  mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal10">
                                    <div onClick={() => playvideo("traffic")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img className="img-fluid" src="./images/traffic.png" alt="..." />
                            </div>
                        </div>

                    </div>

                    { /* <div className="row mb-1 justify-content-center text-center">

                        <div className="col-12 col-md-4  mt-2">
                            <div className="portfolio-item mx-auto" data-bs-toggle="modal" data-bs-target="#portfolioModal3">
                                    <div onClick={() => playvideo("lig")} className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className="portfolio-item-caption-content text-center text-white"><i className="fas fa-play fa-3x"></i></div>
                                    </div>
                                    <img className="img-fluid" src="./images/lig.png" alt="..." />
                            </div>
                        </div>
                    </div> */}
            </div>
        </section>
    )
}
export default Films